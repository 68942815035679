"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _goods = require("@/api/goods");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['goodsId'],
  data: function data() {
    return {
      value: "",
      car_id: "",
      options: []
    };
  },
  mounted: function mounted() {
    document.getElementsByClassName('app-main')[0].scrollTop = 0;
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this = this;
      (0, _goods.aliGoodsAllCategoryQuery)(this.goodsId).then(function (res) {
        console.log(res);
        _this.options = res || {};
      });
    },
    handleChange: function handleChange(value) {
      if (value.length > 1) {
        this.car_id = value[value.length - 1];
      }
    },
    create: function create() {
      if (!this.goodsId || !this.car_id) {
        this.$message({
          message: '请选择对应类型',
          type: 'warning'
        });
        return false;
      }
      (0, _goods.aliGoodsCreate)({
        car_id: this.car_id,
        goods_id: this.goodsId
      }).then(function (res) {
        console.log(res);
      });
    },
    update: function update() {
      if (!this.goodsId || !this.car_id) {
        this.$message({
          message: '请选择对应类型',
          type: 'warning'
        });
        return false;
      }
      (0, _goods.aliGoodsCreate)({
        car_id: this.car_id,
        goods_id: this.goodsId
      }).then(function (res) {
        console.log(res);
      });
    },
    directUpdate: function directUpdate() {
      if (!this.goodsId || !this.car_id) {
        this.$message({
          message: '请选择对应类型',
          type: 'warning'
        });
        return false;
      }
      (0, _goods.aliGoodsCreate)({
        car_id: this.car_id,
        goods_id: this.goodsId
      }).then(function (res) {
        console.log(res);
      });
    }
  }
};