var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer-page goods_info" }, [
    _c(
      "div",
      { staticClass: "block" },
      [
        _c("span", { staticClass: "demonstration" }, [
          _vm._v("选择对应商品类型"),
        ]),
        _c("el-cascader", {
          attrs: { options: _vm.options },
          on: { change: _vm.handleChange },
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "page-footer-btns" },
      [
        _c(
          "el-button",
          {
            attrs: { size: "", type: "primary" },
            on: {
              click: function ($event) {
                return _vm.create()
              },
            },
          },
          [_vm._v("上传")]
        ),
        _c(
          "el-button",
          {
            attrs: { size: "", type: "warning" },
            on: {
              click: function ($event) {
                return _vm.update()
              },
            },
          },
          [_vm._v("更新")]
        ),
        _c(
          "el-button",
          {
            attrs: { size: "", type: "warning" },
            on: {
              click: function ($event) {
                return _vm.directUpdate()
              },
            },
          },
          [_vm._v("免审")]
        ),
        _c(
          "el-button",
          {
            attrs: { size: "", type: "danger" },
            on: {
              click: function ($event) {
                return _vm.$router.back()
              },
            },
          },
          [_vm._v("关闭")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }